import * as React from "react"
import Container from '../components/Container'
import IndentedContent from '../components/helpers/IndentedContent'
import ContentPage from '../components/layout/ContentPage'
import SectionEntry from '../components/SectionEntry'
import SectionEntryContent from '../components/SectionEntryContent'
import SectionEntryTitle from '../components/SectionEntryTitle'
import {Steps} from 'antd';
import SEO from '../components/seo'

const {Step} = Steps;

// markup
const RoadmapPage = () => {
    return (
        <main>
            <SEO title="Roadmap"/>

            <Container containerStyles={{
                pointerEvents: 'none',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '700px',
                backgroundImage: 'radial-gradient(at left top, rgba(81, 144, 224, 0.08) 20%, transparent 80%), radial-gradient(at right center, rgba(122, 81, 224, 0.1) 20%, transparent 75%)'
            }}>
            </Container>

            <ContentPage>
                <Container>
                    <IndentedContent>
                        <SectionEntry>
                            <SectionEntryTitle>Roadmap</SectionEntryTitle>

                            <SectionEntryContent>
                                Offenheit bildet einen Grundpfeiler unserer Arbeit und ermöglicht es dir, an der
                                Weiterentwicklung von petoffice teilzuhaben.
                                Hier findest du eine Liste neuer und zukünftiger Funktionen.
                            </SectionEntryContent>
                        </SectionEntry>
                    </IndentedContent>
                </Container>

                <Container>
                    <IndentedContent>
                        <div className="roadmap-section">
                            <div className="roadmap-section__meta">
                                <h3>Aktuelle Version</h3>

                                <div style={{color: 'rgba(29, 49, 80, 0.5)', fontSize: '0.875rem'}}>
                                    <p>
                                        All diese Funktionen wurden in der aktuellen Version von petoffice umgesetzt und
                                        stehen deinem Verein
                                        bereits zur Verfügung.
                                    </p>
                                </div>
                            </div>
                            <div className="roadmap-section__content">
                                <Steps progressDot current={7} direction="vertical">
                                    <Step title="Passwortloser Login"
                                          description="Melde dich sicher und komfortabel mit Passkeys an"/>
                                    <Step title="Passwort vergessen"
                                          description="Setze dein Passwort selbst zurück und entsperre deinen Zugang"/>
                                    <Step title="Erweiterung Eigene Felder"
                                          description="Du kannst nun Auswahlfelder verwenden"/>
                                    <Step title="Legitimierung Digitaler Schutzvertrag"
                                          description="Digitale Schutz- und Pflegeverträge erfordern nun Nachname und PLZ des Empfängers"/>
                                    <Step title="Kontakte speichern"
                                          description="Personen können direkt über die App im Adressbuch des Smartphones gespeichert werden"/>
                                    <Step title="Zum Ziel navigieren"
                                          description="Navigiere direkt zu der Adresse einer Person, indem du sie anklickst"/>
                                    <Step title="Benutzerführung Tiervermittlung"
                                          description="Vereinfachte Benutzerführung bei der Vermittlung von Tieren und der Zuweisung von Pflegestellen"/>
                                </Steps>
                            </div>
                        </div>
                    </IndentedContent>
                </Container>

                <Container
                    containerStyles={{backgroundImage: 'radial-gradient(at left top, rgba(81, 144, 224, 0.08) 20%, transparent 80%), radial-gradient(at right center, rgba(122, 81, 224, 0.1) 20%, transparent 75%)'}}>
                    <IndentedContent>
                        <div className="roadmap-section">
                            <div className="roadmap-section__meta">
                                <h3>In der nächsten Version</h3>

                                <div style={{color: 'rgba(29, 49, 80, 0.5)', fontSize: '0.875rem'}}>
                                    <p>
                                        Wir arbeiten mit Hochdruck an diesen neuen Funktionen, die dir und deinem Verein
                                        zukünftig die Arbeit noch leichter machen.
                                    </p>
                                </div>
                            </div>
                            <div className="roadmap-section__content">
                                <Steps progressDot current={1} direction="vertical">
                                    <Step title="Erweiterung Zusatzfelder"
                                          description="Zusatzfelder vom Typ Person und Tier können angelegt werden"/>
                                    <Step title="Erweiterung TASSO"
                                          description="Registriere Tiere direkt auf deinen Verein und entscheide, ob eine neue Plakette benötigt wird"/>
                                    <Step title="TASSO-relevante Daten"
                                          description="Aktualisiere bestehende Registrierungen automatisch, wenn sich die Stammdaten des Adoptanten ändern"/>
                                    <Step title="Schwarze Liste für Adoptanten"
                                          description="Hinterlege schlechte Erfahrungen mit Bewerbern und lasse dich bei Kontakt warnen"/>
                                    <Step title="Dauerpflegestelle"
                                          description="Gesonderte Anzeige für Tiere auf Dauerpflegestelle"/>
                                    <Step title="Bearbeiten des Bestandsbuches"
                                          description="Korrigiere Bestandsbucheinträge oder füge Notizen hinzu"/>
                                </Steps>
                            </div>
                        </div>
                    </IndentedContent>
                </Container>

                <Container>
                    <IndentedContent>
                        <div className="roadmap-section">
                            <div className="roadmap-section__meta">
                                <h3>Zukünftig</h3>

                                <div style={{color: 'rgba(29, 49, 80, 0.5)', fontSize: '0.875rem'}}>
                                    <p>
                                        Diese Funktionen stehen bereits fest und werden in den nächsten Versionen
                                        umgesetzt. Sprich uns gerne mit Verbesserungsvorschlägen an,
                                        um an der Planung neuer Funktionen teilzuhaben.
                                    </p>
                                </div>
                            </div>
                            <div className="roadmap-section__content">
                                <Steps progressDot direction="vertical">
                                    <Step title="Erweiterung Eigene Ansichten"
                                          description="Spezielle Berechtigungen zum Export, Anzeige von Impfdaten"/>
                                    <Step title="E-Mail-Versand aus petoffice"
                                          description="Sende personalisierte E-Mails aus Vorlagen bei verschiedensten Ereignissen"/>
                                    <Step title="WordPress Plugin"
                                          description="Zeige deine Vermittlungstiere direkt in WordPress an"/>
                                    {/*<Step title="JavaScript Homepage-Integration"*/}
                                    {/*      description="Zeige deine Vermittlungstiere auf Drittanbieter-Homepages an"/>*/}
                                    <Step title="Homepage-Funktion"
                                          description="Erstelle deine gesamte Homepage direkt aus petoffice heraus"/>
                                </Steps>
                            </div>
                        </div>
                    </IndentedContent>
                </Container>
            </ContentPage>
        </main>
    )
}

export default RoadmapPage
